<template>
    <div class="page daily-bonus-page">
        <div class="page__title">
            <nav-back />
            Ежедневный бонус
            <ui-button color="blue" class="page__title-button" @clicked="onSaveClick" :disabled="!anyChanges">
                Сохранить
            </ui-button>
        </div>
        <template v-if="pageIsReady">
            <div class="daily-bonus-page__status">
                <label class="my-0">Статус:</label>
                <b-dropdown :text="statusText">
                    <b-dropdown-item
                        v-for="(status, i) of statusOptions" :key="i" @click="onStatusSelect"
                        :data-value="status.value"
                    >
                        {{ status.name }}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="daily-bonus-page__day-in-seconds">
                <label>Кол-во секунд в дне</label>
                <input
                    type="number" class="form-control" v-model.number="dayLengthInSeconds"
                    min="0" oninput="validity.valid||(value=0)"
                />
            </div>
            <div class="daily-bonus-page__items-list">
                <draggable
                    class="list-group"
                    tag="ul"
                    v-model="items"
                    v-bind="dragOptions"
                    @start="dragging = true"
                    @end="dragging = false"
                    @change="onOrderChanged"
                    :move="onItemMoved"
                >
                    <transition-group type="transition" name="flip-list" class="d-flex flex-wrap">
                        <li
                            class="list-group-item daily-bonus-page__item"
                            v-for="item of items"
                            :key="item.Day"
                        >
                            <daily-bonus-item
                                v-bind:initial-data="item"
                                ref="dailyBonusItems"
                                @changed="onItemSettingsChanged"
                                @deleted="onDeleteItem"
                            />
                        </li>
                    </transition-group>
                </draggable>
                <ui-button color="blue" class="float-left mt-3" @clicked="onAddDayClick">
                    Добавить день
                </ui-button>
            </div>
        </template>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';
import bus from '@/plugins/bus';

export default {
    data: () => ({
        pageIsReady: false,
        anyChanges: false,
        items: [],
        dragging: false,
        dragLocked: false,
        
        statusText: '',
        statusOption: 1,
        statusOptions: [
            { value: 1, name: 'Включено' },
            { value: 0, name: 'Выключено' },
        ],
        dayLengthInSeconds: 0,
    }),
    components: {
        draggable,
        UiButton: () => import('../../components/ui/UiButton'),
        NavBack: () => import('../../components/navigation/NavBack'),
        DailyBonusItem: () => import('../../components/dailyBonus/DailyBonusItem')
    },
    computed: {
        ...mapGetters('firebase', ['dailyBonuses']),
        dragOptions() {
            return {
                animation: 0,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
                direction: 'vertical'
            };
        },
    },
    async created() {
        await this.getConfig();
        this.onConfigChanged();
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.onConfigChanged)
            .$on(this.FIREBASE_CONFIG_CHANGED_EVENT, this.onConfigChanged);
    },
    beforeDestroy() {
        bus.$off(this.FIREBASE_CONFIG_CHANGED_EVENT, this.onConfigChanged);
    },
    methods: {
        ...mapActions('firebase', ['getConfig', 'updateDailyBonusesConfig']),
        onConfigChanged() {
            this.pageIsReady = false;
            this.statusOption = +this.dailyBonuses?.IsEnabled;
            this.statusText = this.statusOptions.find(status => status.value === this.statusOption)?.name;
            this.items = this.dailyBonuses.Bonuses ? Object.values(this.dailyBonuses.Bonuses) : [];
            this.dayLengthInSeconds = this.dailyBonuses?.DayLengthInSeconds || 0;
            
            setTimeout(() => this.pageIsReady = true, 0);
        },
        onOrderChanged() {
            this.items.forEach((item, i) => {
                item.Day = i + 1;
                this.$refs.dailyBonusItems[i].setData(item);
            });
            this.anyChanges = true;
        },
        onItemMoved() {
            return !this.dragLocked;
        },
        onItemSettingsChanged(day, property, value) {
            const item = this.items.find(item => item.Day === day);
            if (!item || !item.hasOwnProperty(property)) return;
            item[property] = value;
            this.anyChanges = true;
        },
        async onSaveClick() {
            if (!this.anyChanges)
                return;
            
            const Bonuses = [];
            for (let i = 0; i < this.$refs.dailyBonusItems.length; i++)
                Bonuses.push(this.$refs.dailyBonusItems[i].getData());
            
            const response = await this.updateDailyBonusesConfig({
                IsEnabled: this.statusOption === 1,
                DayLengthInSeconds: this.dayLengthInSeconds,
                Bonuses
            });
            
            if (!response.error)
                bus.$emit('show-notification-message', 'Сохранено');
            else
                bus.$emit('show-notification-message', `Ошибка: ${response.error.toString()}`);
            
            this.anyChanges = false;
        },
        onAddDayClick() {
            this.items.push({
                id: this.items.length + 1,
                available: true,
                is_completed: false,
                ads_bonus: 0,
                reward: { id: 1, value: 0 },
                vip_reward: { id: 1, value: 0 },
            });
            this.anyChanges = true;
        },
        onDeleteItem(day) {
            if (!confirm(`Вы точно хотите удалить День #${day}?`)) return;
            this.items.splice(day - 1, 1);
            this.items.forEach((item, i) => {
                item.Day = i + 1;
                this.$refs.dailyBonusItems[i].setData(item);
            });
            this.anyChanges = true;
        },
        onStatusSelect(event) {
            this.statusOption = parseInt(event.target.dataset.value, 10);
            this.statusText = this.statusOptions.find(status => status.value === this.statusOption)?.name;
            this.anyChanges = true;
        }
    }
};
</script>

<style lang="scss">
.daily-bonus-page {
    &__items-list {
        .list-group {
            flex-direction: column;
        }
    }
    
    &__item {
        width: 100%;
        border: 1px solid rgb(84 84 84 / 22%) !important;
        border-radius: 8px !important;
        margin: 4px 0;
    }
    
    &__status {
        display: grid;
        grid-template-columns: 1fr .1fr;
        align-items: center;
        margin-bottom: 8px;
        text-align: left;
    }
    
    &__day-in-seconds {
        display: grid;
        grid-template-columns: 1fr .1fr;
        margin-top: 12px;
        margin-bottom: 12px;
        
        label {
            text-align: left;
            flex-shrink: 0;
            margin-right: 12px;
        }
    }
}
</style>
